import React from 'react';
import {Link} from 'gatsby';
import {Card, CardBody, CardTitle, Button} from 'reactstrap';
import Img from 'gatsby-image';


 const Post = ({title , slug, image}) => {
    return(   
      <Link to={`/${slug}`}><Card> 
          <Img fluid={image}/>
        <CardBody>
          <CardTitle className="text-center">{title}</CardTitle>
        </CardBody>
      </Card></Link>
    )
}

export default Post