import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import {Container, Row, Col} from 'reactstrap'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Post from "../components/Post"

const IndexPage = () => (
  <Layout>
    <SEO title="Inicio" />
    <h1>TASACIONES</h1>


    <StaticQuery
      query={indexQuery}
      render={data => {
        return (
          <div className='d-flex justify-content-center flex-wrap'>
            {data.allMdx.edges.map(({node}) => (
              <div  className='col-lg-4 col-md-6 col-xs-12' key ={node.id}>
              <Post 
              key ={node.id}
              title={node.frontmatter.title} 
              slug={node.fields.slug} 
              image={node.frontmatter.image.childImageSharp.fluid} />
              </div>
            ))}
         </div>
        )
      }}
    />
  </Layout>
)
const indexQuery = graphql`
query MyQuery {
  allMdx {
    edges {
      node {
        id
        fields {
          slug
        }
        frontmatter {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 800, maxHeight:500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }

      }
    }
  }
}
`

export default IndexPage
